import { useCallback } from "react";

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (
              allErrors: any,
              currentError: { path: any; type: any; message: any }
            ) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const videoConstraintsWaitingRoom = {
  width: 550,
  height: 400,
  borderRadius: 10,
  facingMode: "user",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
};

export const videoConstraints = {
  width: 800,
  height: 500,
  borderRadius: 10,
  facingMode: "user",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
};

export interface ValidationError {
  message: string;
  errors: Record<string, string[]>;
}

export const VideoConstants = {
  FileName: "recorded-video.webm",
  FileType: "video/webm",
};

export const progressConstants = {
  ice_breaker: 0.05,
  relevance: 0.15,
  technical: 0.35,
  competency: 0.75,
  logistical: 0.95,
};
